import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#6b8e23">
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2014 Sep - 2018 Oct"
          iconStyle={{ background: "#6b8e23", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Pokhara University
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Bachelor's Degree
          </h4>
          <p> Computer Engineering</p>
          <p> Project based academic experience using C, C++ and Embedded System</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2017 Aug - 2020 Jan"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Software Developer - Softechpark Pvt. Ltd
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Kathmandu, Nepal
          </h4>
          <p>
            Developed and maintained web application using Java, REST APIs, Spring boot,
            Spring Security, Spring AOP, PostgreSQL, MongoDB.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2020 Jan- 2022 July"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Software Engineer - EKbana Solutions Pte. Ltd
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Lalitpur, Nepal
          </h4>
          <p>
            Developed Microservices using Java, Spring boot, Hibernate, MongoDB, PostgreSQL,
            Spring Security, Zipkin, Kafka, Vault,  JUnit, Mockito, GitHub, Jenkins,
            Docker, React, AWS Lambda, S3, ECS, SNS.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2022 Aug- present"
          iconStyle={{ background: "#6b8e23", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Maharishi International University
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Master's Degree
          </h4>
          <p> Computer Science</p>
          <p>
            Profesisonal growth through project based academic experience. Involved in multiple academic
            projects including Library Management, Movie Rating Portal, Portfolio Site Management, Micro Bank
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
