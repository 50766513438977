import Enotify from "../assets/enotify.png";
import Hgi from "../assets/hgi.png";
import Insurance from "../assets/Insurance.png";
import Pool from "../assets/pool.png";
import Ltp from "../assets/ltp.png";
import AutoCAD from "../assets/AutoCAD.png";
import CIB from "../assets/CIB.png";

export const ProjectList = [
  {
    title: "Meyco Order System",
    picture: Pool,
    infrastructure:
      "Java, REST APIs, Spring boot, Spring Security, Spring AOP, PostgreSQL, React.js, Git, GitHub.",
  },
  {
    title: "LTP Subscriptions",
    picture: AutoCAD,
    infrastructure: "C#, Blazor, Entity Framework, PostgreSQL, HTML, CSS",
  },
  {
    title: "HGI Insurance",
    picture: Insurance,
    infrastructure:
      "Java, Spring boot, Hibernate, MongoDB, PostgreSQL, Spring Security, Zipkin, Kafka, Vault,  JUnit, Mockito, GitHub, Jenkins, Docker, React, AWS Lambda, S3, ECS, SNS",
  },
  {
    title: "Credit Information Bureu Reporting - Civil Bank",
    picture: CIB,
    infrastructure:
      "C#, ADO.NET, MS SQL, Stored Procedure, HTML, CSS",
  },
  {
    title: "E-notify",
    picture: Enotify,
    infrastructure:
      "Java, JDBC, HTML, CSS, jQuery, AWS Email Service",
  },
];
