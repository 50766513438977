import React from "react";
import Athena from "../assets/Athena.png";
import Ebs from "../assets/Ebs.png";
import { useNavigate } from "react-router-dom";

function MyBlog() {
  const navigate = useNavigate();
  return (
    <div className="projectList">
      <div
        className="projectItem"
        onClick={() => {
          navigate(
            window.open(
              "https://medium.com/@thpchandrika/aws-athena-be07daa78dde"
            )
          );
        }}
      >
        <div
          style={{ backgroundImage: `url(${Athena})` }}
          className="bgImage"
        />
        <h1> Amazon Athena </h1>
        <a
          href="https://medium.com/@thpchandrika/aws-athena-be07daa78dde"
          target="_blank"
          rel="noreferrer"
        >
          See more...
        </a>
      </div>
      <div
        className="projectItem"
        onClick={() => {
          navigate(
            window.open(
              "https://medium.com/@thpchandrika/aws-elastic-beanstalk-deploy-and-scale-web-application-1eb981676090"
            )
          );
        }}
      >
        <div style={{ backgroundImage: `url(${Ebs})` }} className="bgImage" />
        <h1> Amazon EBS </h1>
        <a
          href="https://medium.com/@thpchandrika/aws-elastic-beanstalk-deploy-and-scale-web-application-1eb981676090"
          target="_blank"
          rel="noreferrer"
        >
          See more...
        </a>
      </div>
    </div>
  );
}

export default MyBlog;
