import React, { useEffect } from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";
import InstaIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home">
      <div className="about">
        <h2> Hi, I am Chandrika Thapa</h2>
        <div className="prompt">
          <p>
            A passionate software developer interested in learning new
            technology.
          </p>
          {/* <a
            href=""
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon />
          </a> */}
          <LinkedInIcon />
          <EmailIcon />
          {/* <a
            href="#"
            target="_blank"
            rel="noreferrer"
          >
            <GitHubIcon />
          </a> */}
          <GitHubIcon />
          <InstaIcon />
          <FacebookIcon />
        </div>
      </div>
      <div className="skills">
        <h1> Skills</h1>
        <ol className="list">
          <li className="item">
            <h2> Front-End</h2>
            <span>
              HTML, CSS, JavaScript, BootStrap, JQuery, ReactJS
            </span>
          </li>
          <li className="item">
            <h2>Back-End</h2>
            <span>
              SpringBoot, Spring MVC, Hibernate, Apache Kafka, RestAPIs
              .NET, Express.js, PostgreSQL, MongoDB
            </span>
          </li>
          <li className="item">
            <h2>Languages</h2>
            <span>Java, C#, JavaScript</span>
          </li>
          <li className="item">
            <h2>AWS</h2>
            <span>
              CloudWatch, IAM, EC2, API Gateway, Lambda, S3, CloudFront, IAM, SNS, SQS, CloudFront,
              Route53, DynamoDB
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;
