import React from "react";
import { useParams } from "react-router-dom";
import { ProjectList } from "../helpers/ProjectList";
import GitHubIcon from "@material-ui/icons/GitHub";
import "../styles/ProjectDisplay.css";

function ProjectDisplay() {
  const { id } = useParams();
  const project = ProjectList[id];
  return (
    <div className="project">
      <h1> {project.title}</h1>
      <img src={project.picture} alt={project.title} />
      <p>
        <b>Tech: </b> {project.infrastructure}
      </p>
      {/* <GitHubIcon /> */}
    </div>
  );
}

export default ProjectDisplay;
