import React, { useState, useEffect } from "react";
import "../styles/Contact1.css";
import axios from "axios";

function Contact1() {
  useEffect(() => {
    // Load Bootstrap stylesheet when the component mounts
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css";
    document.head.appendChild(link);

    // Remove Bootstrap stylesheet when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  let [responseBody, setResponseBody] = useState(null);
  let [messageColor, setMessageColor] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    const post = {
      fullName: fullName,
      emailAddress: emailAddress,
      subject: subject,
      message: message,
    };
    try {
      const res = await axios.post(
        "https://bahk8nikt6.execute-api.us-east-1.amazonaws.com/v1/contact",
        post
      );
      setResponseBody(res.data);
      if (res.data.statusCode === 201) {
        setMessageColor("green");
        document.getElementById("contact_form").reset();
      } else {
        setMessageColor("red");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center">Contact Us</h2>
      <div className="contact-form">
        <form id="contact_form" onSubmit={submitHandler}>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              className="form-control"
              name="user_name"
              required
              placeholder="Enter your name"
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              className="form-control"
              name="user_email"
              required
              placeholder="Enter your email"
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Subject:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your subject"
              name="subject"
              required
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Message:</label>
            <textarea
              className="form-control"
              name="message"
              rows="4"
              placeholder="Enter your message"
              required
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary btn-block">
            Send
          </button>
          <label className="label" style={{ color: messageColor }}>
            {responseBody === null ? "" : responseBody.message}
          </label>
        </form>
      </div>
    </div>
  );
}

export default Contact1;
