import React from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import GithubIcon from "@material-ui/icons/GitHub";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        {/* <a
          href=""
          target="_blank"
          rel="noreferrer"
        >
          <LinkedInIcon />
        </a>
        <LinkedInIcon /> */}
        {/* <a
          href=""
          target="_blank"
          rel="noreferrer"
        >
          <GithubIcon />
        </a> */}
        <GithubIcon />
        <InstagramIcon />
        <LinkedInIcon />
        <FacebookIcon />
      </div>
      <p> &copy; 2022 chandrikathapa.com</p>
    </div>
  );
}

export default Footer;
