import React, { useState, useEffect } from "react";
import "../styles/Contact2.css";
import axios from "axios";

function Contact2() {
  useEffect(() => {
    // Load Bootstrap stylesheet when the component mounts
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css";
    document.head.appendChild(link);

    // Remove Bootstrap stylesheet when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  let [responseBody, setResponseBody] = useState(null);
  let [messageColor, setMessageColor] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    const post = {
      fullName: fullName,
      emailAddress: emailAddress,
      subject: subject,
      message: message,
    };
    try {
      const res = await axios.post(
        "https://bahk8nikt6.execute-api.us-east-1.amazonaws.com/v1/contact",
        post
      );
      setResponseBody(res.data);
      if (res.data.statusCode === 201) {
        setMessageColor("green");
        document.getElementById("contact_form").reset();
      } else {
        setMessageColor("red");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <section id="contact">
      <div className="mt-3 contactContent">
        <h1 className="text-center">Contact Me</h1>

        <div className="row mt-4">
          <div className="col-lg-6 pl-4">
            <div
              style={{
                textDecoration: "none",
                overflow: "hidden",
                maxWidth: "100%",
                width: "600px",
                height: "500px",
              }}
            >
              <div
                id="gmap-canvas"
                style={{ height: "100%", width: "100%", maxWidth: "100%" }}
              >
                <iframe
                  style={{ height: "100%", width: "100%", border: "0" }}
                  src="https://www.google.com/maps/embed/v1/place?q=Euless,+TX,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                  title="Euless Texas"
                ></iframe>
              </div>
              <a
                className="our-googlemap-code"
                rel="nofollow"
                href="https://kbj9qpmy.com/hrn"
                id="make-map-data"
              >
                Hosting Right Now
              </a>
            </div>
          </div>

          <div className="col-lg-6 pr-4">
            <form onSubmit={submitHandler}>
              <input
                type="text"
                name="user_name"
                required
                className="form-control"
                placeholder="Name"
                onChange={(e) => setFullName(e.target.value)}
              />
              <input
                type="email"
                className="form-control mt-3"
                placeholder="Email"
                name="user_email"
                required
                onChange={(e) => setEmailAddress(e.target.value)}
              />
              <input
                type="text"
                className="form-control mt-3"
                placeholder="Subject"
                name="subject"
                required
                onChange={(e) => setSubject(e.target.value)}
              />
              <div className="mb-3 mt-3">
                <textarea
                  className="form-control"
                  rows="5"
                  id="comment"
                  name="text"
                  placeholder="Message"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </form>
            <div class="d-flex d-flex justify-content-center">
              <button type="submit" className="btn btn-primary mt-3">
                Contact Me
              </button>
            </div>
            <label className="label mt-3" style={{ color: messageColor }}>
              {responseBody === null ? "" : responseBody.message}
            </label>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact2;
